import { useStaticQuery, graphql } from "gatsby";

export const useRecentBlogsQuery = () => {
  const data = useStaticQuery(graphql`
  query RecentBlogs {
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Blogs"}}}}, status: {eq: "publish"}}
      limit: 4
      ) {
    edges {
      node {
        id
        title
        uri
        slug
        excerpt
        featuredImage {
          node {
            sourceUrl
            title
          }
        }
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
  }
  
  `)

  return data;

}