import React, { useState } from "react"
import Navbar from '../Navigation/Navbar'
import { Link } from "gatsby"
//import Logo from '../../images/company-logo.png'
import { useMenuQuery } from "../../hooks/useMenuQuery"
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'

const Header = () => {
  const { site, internalPage } = useMenuQuery();
  //console.log('Check data', [site, internalPage])
  
  const [toggle , setToggle] = useState(false)
//   let obj = {
//     id: "menu_our_team",
//     label: "Our team",
//     url: "/our-team/"
// }
// internalPage.menuItems.nodes.splice(6, 0, obj);
  const handleToggle = (data) => {
      console.log('handle toggle ',data)
      setToggle(!data)
  }
  return (
    <header id="header" className="main-header">
      <div className="secondary-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="logo-wrap">
                <Link to="/">
                  <StaticImage src="../../images/company-logo.png" alt={site.siteMetadata.title} alt=""/>
                </Link>
              </div>
              <button className={toggle ? 'navbar-toggler first-button active' : 'navbar-toggler first-button'}  onClick={() => handleToggle(toggle)}>
                <div className={toggle ? 'animated-icon1 open' : 'animated-icon1'}>
                  <span />
                  <span />
                  <span />
                </div>
              </button>
              <Navbar menu={internalPage.menuItems.nodes}  toggle={toggle}/>
            </div>
          </div>
        </div>
      </div>
    </header>
  )

}

export default Header;




