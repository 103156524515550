// Create a custom hook. Start name with use and react will know this is a custom hook.
import { useStaticQuery, graphql } from "gatsby";

export const useMenuQuery = () => {
  const data = useStaticQuery(graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        title
      }
    }
    internalPage: wpMenu(name: {eq: "Internal Pages"}) {
      menuItems {
        nodes {
          id
          label
          url
          childItems {
            nodes {
              id
              label
              url
            }
          }
        }
      }
    }
     caseStudies: wpMenu(name: {eq: "Case Studies"}) {
      menuItems {
        nodes {
          id
          label
          url
          childItems {
            nodes {
              id
              label
              url
            }
          }
        }
      }
    }
    aboutM: wpMenu(name: {eq: "About"}) {
      menuItems {
        nodes {
          id
          label
          url
          childItems {
            nodes {
              id
              label
              url
            }
          }
        }
      }
    }
    services: wpMenu(name: {eq: "Services"}) {
      menuItems {
        nodes {
          id
          label
          url
          childItems {
            nodes {
              id
              label
              url
            }
          }
        }
      }
    }
    servicesside: wpMenu(name: {eq: "ServicesSide"}) {
      menuItems {
        nodes {
          id
          label
          url
          childItems {
            nodes {
              id
              label
              url
            }
          }
        }
      }
    }
  }
  `)

  return data;
}