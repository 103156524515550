import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';

import "../../styles/webi-2020-assets-css-bootstrap.min.css"
import "../../styles/webi-2020-assets-css-blog.css"
import "../../styles/webi-2020-assets-fonts-css-font-awesome.css"
import "../../styles/webi-2020-assets-css-global.css"
import "../../styles/webi-2020-assets-css-style.css"
import "../../styles/webi-2020-assets-css-mediaBoxes.css"
import "../../styles/dist-block-library-style.min.css"
import "../../styles/webi-2020-child-style.css"
import "../../styles/custom.css" 

import "../../styles/all.css" 
import "../../styles/style1.css" 

import Header from '../Header/Header'
import Footer from '../Footer/Footer'


const Layout = ({ children }) => {
  //console.log('children', children)
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaRobotsNoindex
                            metaDesc
                        }
                        page {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
                    }
                   
                }
            }
        }
    `);
  return (
    <>
      <Header />
      
      <SEOContext.Provider value={{ global: seo }}>
        <div>{children}</div>
      </SEOContext.Provider>
      <Footer />
      <a href="#" id="buttonToTop" className="show">&nbsp;</a>
    </>
  )
}

export default Layout