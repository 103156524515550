import React from "react"
import { Link } from "gatsby"
import { useMenuQuery } from "../../hooks/useMenuQuery"
import { useRecentBlogsQuery } from "../../hooks/useRecentBlogsQuery"
import { StaticImage } from 'gatsby-plugin-image'

const Footer = () => {
  const { caseStudies, aboutM, services } = useMenuQuery();
  const { allWpPost } = useRecentBlogsQuery();

  const recentBlogs = allWpPost.edges;

  const caseStudyMenu = caseStudies.menuItems.nodes;
  const aboutMenu = aboutM.menuItems.nodes;
  const servicesMenu = services.menuItems.nodes;
  return (
    <footer>
      <div id="footer">
        <div className="primary-footer">
          <div className="container">
            {/*- Start Container */}
            <div className="row">
              {/*- Start Row */}
              <div className="col-12">
                <div className="footer-txt">
                  <div>
                    <div>
                      <div className="textwidget custom-html-widget">
                        <h3
                          style={{
                            fontFamily:
                              '"Roboto Condensed", sans-serif !important',
                            color: "#fff",
                            fontSize: "18px",
                            marginBottom: "10px",
                            marginTop: "-7px",
                          }}
                        >
                          Webuters Technologies Pvt. Ltd.
                        </h3>
                      </div>
                    </div>
                    <div>
                      <div className="textwidget">
                        <p>
                          A full-service cloud consulting and technology
                          solutions company helping businesses of all sizes and
                          across industries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="secondary-footer">
          <div className="container">
            {/*- Start Container */}
            <div className="row">
              {/*- Start Row */}
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="footer-comp-info">
                  <div className="textwidget custom-html-widget">
                    <h3
                      style={{
                        fontFamily: '"Roboto Condensed", sans-serif !important',
                        color: "#fff",
                        fontSize: "18px",
                        marginBottom: "10px",
                        marginTop: "-7px",
                      }}
                    >
                      Webuters Technologies Pvt. Ltd.
                    </h3>
                  </div>
                  <div className="textwidget">
                    <p>
                      A full-service cloud consulting and technology solutions
                      company helping businesses of all sizes and across
                      industries.
                    </p>
                  </div>
                  <div className="textwidget">
                    <div className="footer-address">
                      <p>
                        A-13/2,3,4, Block A, Industrial Area 601, 6th floor,
                        Highway Towers, Sector 62,
                        <br />
                        Noida, Uttar Pradesh 201309
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="footer-link">
                  <li>
                    <div className="textwidget">
                      <p>
                        <a
                          href="mailto:info@webuters.com"
                          target="_top"
                          rel="noopener"
                        >
                          info@webuters.com
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="iso-batch">
                  <StaticImage src='../../images/iso-batch.png' alt=""/>
                </div><br/>
                <div className="social-connect-wrap ">
                  <ul id="menu-social-links" className="menu">
                    <li
                      id="menu-item-6227"
                      className="social-connect facebook menu-item menu-item-type-custom menu-item-object-custom menu-item-6227"
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/webuters"
                      >
                        <i className="fa fa-facebook fa-xs" style="color: #426782;" />
                      </a>
                    </li>
                    <li
                      id="menu-item-6228"
                      className="social-connect twitter menu-item menu-item-type-custom menu-item-object-custom menu-item-6228"
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/webuters"
                      >
                        <i className="fa fa-twitter fa-xs" style="color: #00ACEE;" />
                      </a>
                    </li>
                    <li
                      id="menu-item-6229"
                      className="social-connect linked menu-item menu-item-type-custom menu-item-object-custom menu-item-6229"
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://in.linkedin.com/company/webuters-technologies"
                      >
                        <i className="fa fa-linkedin fa-xs" style="color: #0077b5;" />
                      </a>
                    </li>
                    <li
                      id="menu-item-6230"
                      className="social-connect youtube menu-item menu-item-type-custom menu-item-object-custom menu-item-6230"
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.youtube.com/channel/UCsfjsr7EwCtOoeAgPeIo6KA"
                      >
                        <i className="fa fa-youtube fa-xs" style="color: #FF0000;" />
                      </a>
                    </li>
                  </ul>
                </div>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <div className="row">
                  <div className="col">
                    <div className="footer-nav" style={{ fontSize: "0px" }}>
                      <h3>Recent Blogs</h3>
                      <div className="rpwe-block ">
                        <ul className="rpwe-ul">
                          {recentBlogs.map(rb =>
                            <li key={rb.node.id} className="rpwe-li rpwe-clearfix">
                              <h3 className="rpwe-title">
                                <Link
                                  to={rb.node.uri}
                                >
                                  {rb.node.title}
                                </Link>
                              </h3>
                              <time
                                className="rpwe-time published"
                              >
                                {rb.node.date}
                              </time>
                            </li>

                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="footer-nav">
                      <h3>Services &amp; Products </h3>
                      <div className="menu-services-container">
                        <ul id="menu-services" className="menu">
                          {servicesMenu.map(sm =>
                            !sm.parentId ? (
                              <li key={sm.id}
                                className="menu-item menu-item-type-post_type menu-item-object-post menu-item-6750"
                              >
                                <Link to={sm.url}>
                                  {sm.label}
                                </Link>
                              </li>
                            ) : null
                          )}

                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="footer-nav">
                      <h3> Case Studies </h3>
                      <div className="menu-case-studies-container">
                        <ul id="menu-case-studies" className="menu">
                          {caseStudyMenu.map(cs =>
                            !cs.parentId ? (
                              <li key={cs.id}
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6759"
                              >
                                <Link to={cs.url}>
                                  {cs.label}
                                </Link>
                              </li>
                            ) : null
                          )}

                        </ul>
                      </div>{" "}
                    </div>
                    <div className="footer-nav">
                      <h3> ABOUT US </h3>
                      <div className="menu-about-container">
                        <ul id="menu-about" className="menu">
                          {aboutMenu.map(am =>
                            !am.parentId ? (
                              <li key={am.id}
                                id="menu-item-6747"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6747"
                              >
                                <Link to={am.url}>
                                  {am.label}
                                </Link>
                              </li>
                              // <li key={cs.id}
                              //   className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6759"
                              // >
                              //   <Link to={cs.url}>
                              //     {cs.label}
                              //   </Link>
                              // </li>
                            ) : null
                          )}
                        </ul>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row br-top">
              <div className="col-12 col-sm-12 copy-right text-center">
                <p>
                  {" "}
                  © 2022{" "}
                  <a
                    style={{ color: "white" }}
                    href="/"
                  >
                    Webuters Technologies
                  </a>
                  .All Rights Reserved |{" "}
                  <a href="/privacy-policy/ ">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
