import React from "react"
import { Link } from "gatsby"

const Navbar = ( props ) => {
    const { menu , toggle } = props
    
    
    //console.log('menu:: ',menu)
    //console.log('toggle in nav bar', toggle)
  return (
      
        toggle !== true ?
        <nav className="nav-wrap" >
            <ul id="menu-home" className="menu">
                {menu.map(mainMenu =>
                !mainMenu.parentId ? (
                    <li key={mainMenu.id} className="active menu-item menu-item-type-custom menu-item-object-custom menu-item-6452" >
                    <Link to={mainMenu.url} activeClassName="nav-active">
                        {mainMenu.label}
                    </Link>
                    </li>
                ) : null
                )}
            </ul>
        </nav>
        : 
        <nav className="nav-wrap" style={{display: toggle === true ? 'block' : 'none'}}>
            <ul id="menu-home" className="menu">
                {menu.map(mainMenu =>
                !mainMenu.parentId ? (
                    <li key={mainMenu.id} className="active menu-item menu-item-type-custom menu-item-object-custom menu-item-6452" >
                    <Link to={mainMenu.url} activeClassName="nav-active">
                        {mainMenu.label}
                    </Link>
                    </li>
                ) : null
                )}
            </ul>
        </nav>
      
    
  )
}

export default Navbar
